
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加APP</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
             <div class="left-option">

                <a-input style="width:240px" placeholder="APP" v-model:value="search" />
                <a-button  type="primary">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'file_src'">
                            <a :href="'https://pdxt.13524.net/'+record.file_src">点击下载</a>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑APP -->
        <a-modal v-model:open="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 17 }"
                autocomplete="off"
            >
                <a-form-item label="标题" name="title" >
                    <a-input placeholder="标题" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item label="APP版号" name="app_version" >
                    <a-input placeholder="APP版号" v-model:value="formData.data.app_version" />
                </a-form-item>
                <a-form-item label="APP更新包" name="app_version" >
                    <a v-if="formData.data.file_src" :href="'https://pdxt.13524.net/'+formData.data.file_src">点击下载</a>
                    <a-upload
                        v-model:file-list="fileAudio"
                        name="file"
                        action="https://pdxt.13524.net/admin/addappfile"
                        :headers="headers"
                        @change="handleChangeEdit"
                    >
                        <a-button size="small"><upload-outlined></upload-outlined>上传APP包</a-button>
                    </a-upload>
                </a-form-item>
                <!-- <a-form-item label="线索缩写" name="clue_project_abbr" >
                    <a-input placeholder="线索缩写" v-model:value="formData.data.clue_project_abbr" />
                </a-form-item>
                <a-form-item label="所属管理员" name="admin_user_id" v-has="'管理员添加APP'">
                    <a-select ref="select"  v-model:value="formData.data.admin_user_id"   >
                        <a-select-option v-for="item in padmin.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                    </a-select>
                </a-form-item> -->
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '标题',dataIndex: 'title'},
    {title: 'APP版号',dataIndex: 'app_version'},
    {title: 'APP链接',dataIndex: 'file_src'},

    {title: '添加日期',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let padmin=reactive({arr:[]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页


        // 线索列表
        let institutionList=reactive({arr:[]})





         // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/applist',{page:page.page,pagesize:page.pageSize,title:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{app_version:"",title:"",file_src:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.clue_project_abbr=""
            formData.data.admin_user_id=""
            title.value="添加APP"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/cluestatepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑APP"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
          
            if(title.value=="添加APP"){
                proxy.$http.post('/admin/addapp',{title:formData.data.title,app_version:formData.data.app_version,file_src:formData.data.file_src},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑APP"){
                proxy.$http.post('/admin/updatecluestate',{id:formData.data.id,title:formData.data.title,app_version:formData.data.app_version,file_src:formData.data.file_src},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delapp',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

         // 上传APP
        let fileAudio=ref()

        let handleChangeEdit=function(info){
            if (info.file.status === 'uploading') {
                return;
            }else if(info.file.status === 'done'){
                formData.data.file_src=info.fileList[0].response.data.file_src
              
            }else if(info.file.status === 'error'){
                message.error("上传失败");
            }
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            headers,fileAudio,handleChangeEdit,
            institutionList,
            toSearch,search,
            visible,formData,openAdd,title,openEdit,handleOk,padmin,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>