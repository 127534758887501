<template>
   <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>
</template>

<style>
  @import './common/font.css';
  body,html{height:100%;overflow: hidden;}
  #app{height: 100%;}
</style>

<script>
import { getCurrentInstance } from 'vue'

import zhCN from 'ant-design-vue/es/locale/zh_CN';
  export default {
    data(){
          return{
              locale: zhCN,
          }
      },
      setup(){
        let getFirstbtn=function(){
             var list=sessionStorage.getItem('menulist')
            list=eval('(' + list + ')')
            for(var i in list){
                if(list[i].title=="首页"){
                  sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                }
            }
        }
        getFirstbtn()

        const { proxy } = getCurrentInstance()
        let getTitle=function(){
           proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':"jy"
                }
            }).then((res) => {
                if(res.data.status==200){
                    sessionStorage.setItem("netTitle",res.data.data.data.title)
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTitle()
      }
  }
</script>
