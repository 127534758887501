<template>
    <div >
        <!-- <div class="analysis-top" style="margin:16px 0 0 14px">
            <span>拨打线索：{{total1.data.call_no}}</span>
            <a-divider type="vertical" />
            <span>接通数：{{total1.data.call_no_through}}</span>
            <a-divider type="vertical" />
            <span>接通率：{{total1.data.call_no_rate}}</span>
            <a-divider type="vertical" />
            <span>接通时长(分)：{{total1.data.call_no_duration_i}}</span>
        </div>-->
        <a-divider style="margin:16px 0" /> 
        <div class="search">
            <div class="left-option flexwrap2">
                <div>
                    <a-range-picker :presets="rangePresets"  valueFormat="YYYY-MM-DD" v-model:value="searchLlist.date" />

                    <a-select
                        ref="select" v-model:value="searchLlist.admin_user_id" style="width: 120px;margin-left:14px"
                        placeholder="通信坐席"
                        allowClear
                    >
                        <a-select-option v-for="item in userList.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                    </a-select>

                    <!--  <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                        placeholder="机构"
                        allowClear
                    >
                        <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                    <a-select ref="select" v-model:value="searchLlist.verify_state" style="width: 120px;margin-left:14px"
                        placeholder="核实状态"
                        allowClear
                    >
                        <a-select-option value="0">待核实</a-select-option>
                        <a-select-option value="1">有效</a-select-option>
                        <a-select-option value="2">无效</a-select-option>
                    </a-select>

                    <a-input style="width:90px;margin-left:14px" placeholder="客户姓名" v-model:value="searchLlist.username" /> -->
                    <!-- <a-input style="width:120px;margin-left:14px" placeholder="线索号码" v-model:value="searchLlist.phone" /> -->
                    <!-- <a-select v-if="istab" ref="select" placeholder="线索状态" style="width: 120px;margin-left:14px"  v-model:value="searchLlist.clue_state"   allowClear>
                        <a-select-option v-for="item in cluestatuslist.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select> -->
                    <a-button @click="toSearch" type="primary">查询</a-button>
                </div>
                <a-button style="margin-top:0" class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
                
            </div>
            <div class="table-wrap">
                <a-table  row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" size="small"  >
                    
                    <template #bodyCell="{ column,record}">

                        <template v-if="column.dataIndex === 'type'">
                            <a-tag v-if="record.type=='101'" color="green">增加线索</a-tag>
                            <a-tag v-if="record.type=='102'" color="green">增加派单</a-tag>

                            <a-tag v-if="record.type=='201'" >减少线索</a-tag>
                            <a-tag v-if="record.type=='202'" >减少派单</a-tag>

                            <a-tag v-if="record.type=='301'" color="red">消费线索</a-tag>
                            <a-tag v-if="record.type=='302'" color="red">消费派单</a-tag>
                        </template>

                        
                    </template>
                </a-table>
                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>

        </div>

        
    </div>
</template>

<script>
const columns = [
    { title: 'ID', dataIndex: 'id' ,width:90},
    {title: '拨打坐席',dataIndex: 'username_admin_user'},
    {title: '主叫号码',dataIndex: 'admin_user_telephone'},
    {title: '被叫号码',dataIndex: 'called_number'},
    {title: '通话时长',dataIndex: 'call_second'},
    // {title: '计费时长',dataIndex: 'data_no'},
    {title: '通信单价',dataIndex: 'call_unit_price_per_minute'},
    {title: '通信费用',dataIndex: 'price'},
    {title: '呼叫时间',dataIndex: 'create_time',width:170}

];

import { reactive ,ref} from '@vue/reactivity'
import { getCurrentInstance, computed } from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import {useRouter} from "vue-router";
import moment from "moment";
import { formatDate } from "../../common/filter.js";
import "dayjs/locale/zh-cn";
export default {
    data(){
        return{
            noClick: true
        }
    },
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const router=useRouter()
        let searchLlist=reactive({date:"",admin_user_id:undefined})
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        const rangePresets = ref([
            { label: '今天', value: [dayjs().add(0, 'd'), dayjs()] },
            { label: '昨天', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
            { label: '最近7天', value: [dayjs().add(-6, 'd'), dayjs()] },
            { label: '最近30天', value: [dayjs().add(-29, 'd'), dayjs()] },
        ]);

        // let changtab=function(){
           
        //     getTabledata()
        // }

        // 客服列表
        let userList=reactive({arr:[]})
        let getUserlist=function(){
            proxy.$http.post('/admin/adminuserlist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserlist()

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }


        let page=reactive({page:"",pageSize:10,total:20})//分页
        let total1=reactive({data:{}})
        let getTabledata=function(){
            var date=""
            if(searchLlist.date){
                date=moment(searchLlist.date[0]+" 00:00:00").unix()+"-"+moment(searchLlist.date[1]+" 23:59:59").unix()
            }
            
            proxy.$http.post('/admin/seatscostbalancestatisticslist',{page:page.page,pagesize:page.pageSize,create_time:date,
            admin_user_id:searchLlist.admin_user_id
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    total1.data=res.data.data.data_statistics
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()


        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }

        return{
            reload,
            total1,
            userList,changeFormdate,
            toSearch,
            pageChange,getTabledata,dataList,page,rangePresets,
            searchLlist,columns
        }
    }
}
</script>