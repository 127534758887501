export default {
    mounted(el, binding) {
      const permission = binding.value;
  
      let hasPermission = false; // 設置初始權限為 false
      const buttonpermsStr = sessionStorage.getItem('btnlist');
      // console.log(1)
      // console.log(buttonpermsStr)
      if (buttonpermsStr&&buttonpermsStr!="undefined") {

        const buttonPermissions = JSON.parse(buttonpermsStr);
        // 使用一個 flag 來記錄是否有匹配的權限
        hasPermission = buttonPermissions.some(element => element.title === permission);
      }
  
      if (!hasPermission) {
        el.style.display = 'none'; // 隱藏元素
      }
    }
  };
  







  
  
  
  