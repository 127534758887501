<template>
  <a-layout style="height:100%">
    <a-layout-sider v-model:collapsed="collapsed" theme="light"  :trigger="null" collapsible>
      <div class="logo" >
        <img :src="'https://pdxt.13524.net/'+system.data.log_url" alt="">
      </div>
      <a-menu  mode="inline">
        <!-- {{menulist.arr}} -->
        <!-- <a-menu-item key="999" @click="toRouter('首页','/index')">
            <DesktopOutlined />
            <span>首页</span>
        </a-menu-item> -->
        <template v-for="item in menulist.arr">
          <a-sub-menu :key="item.id" v-if="item.children">
            
            <template #title>
              <span>
                <component :is="item.icon" />
                <span>{{item.title}}</span>
              </span>
            </template>
            <a-menu-item @click="toRouter(item.title,option.href,option.title)" v-for="option in item.children" :key="option.id">{{option.title}}</a-menu-item>
          </a-sub-menu>

          <a-menu-item :key="item.id" v-else @click="toRouter(item.title,item.href)">
            <component :is="item.icon" />
            <span>{{item.title}}</span>
          </a-menu-item>
        </template>

      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0 30px 0;display: flex;justify-content: space-between;align-items: center;">
     
        
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
        />
        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
        <div class="head-menu-right" style="display: flex;align-items: center;">
          <a-tooltip>
            <template  #title>商机通回呼</template>
            <whats-app-outlined v-if="accountInfo.data.isverify_sjt==0&&accountInfo.data.phone_call_api_type==1" style="font-size:16px;color:3888"   type="primary" @click="binding" />
          </a-tooltip>

          <!-- <div class="communicate-index" @click="call">
            <h2>打个电话，联系客户 </h2>
            <div class="tel-img">
              <img src="../assets/tel.svg" alt="">
            </div>
          </div> -->

          <a href="https://doc.13524.net/?g=Doc&m=Article&a=index&id=8" style="color:#3c3c3c;" target="_blank">手机版帮助文档</a>

          <a-popover title="">
            <template #content>
              <p style="text-align:center;margin-bottom:12px">安卓手机版下载</p>
              <img style="width:120px" src="../assets/app.png" alt="">
            </template>
            <a-button type="link" >安卓下载</a-button>
          </a-popover>
          
           <a-popover title="">
            <template #content>
              <p style="text-align:center;margin-bottom:12px">苹果手机请搜索“飞象系统”</p>
              <img style="width:160px" src="../assets/ios.png" alt="">
            </template>
            <a-button type="link" >IOS下载</a-button>
          </a-popover>

           
        

          <a-divider type="vertical" style="height: 20px; margin:0 20px"/>

          <!-- <a-button style="margin-right:16px" v-if="accountInfo.data.isverify_sjt==0" type="primary" @click="binding">蚁巢网页电话验证</a-button> -->
          <a-dropdown placement="bottomRight">
            <a class="ant-dropdown-link" @click.prevent>
              <div class="head-info">
                <img src="../assets/head.png" alt="">
                <p>{{accountInfo.data.username}}</p>
                <DownOutlined />
              </div>
              
            </a>
            <template #overlay>
              <a-menu>
                <div class="head-info-menu">
                  <img src="../assets/head.png" alt="">
                  <div>
                    <p @click="toPerson">{{accountInfo.data.username}} <RightOutlined /></p>
                    <p>{{accountInfo.data.title_admin_role}}</p>
                  </div>
                </div>
                <a-menu-item @click="toLoginout" key="3">退出登录</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '24px 16px', background: '#fff', minHeight: '280px',overflowY:'auto',overflowX:'hidden'  }"
      >
         <router-view/>
      </a-layout-content>
    </a-layout>

    

  <!-- 拨打键盘 -->
  <div class="communicate-body" v-if="callshow">
      <div>
        <div class="communicate-head">
          <!-- <a-dropdown> -->
            <a class="ant-dropdown-link" >
              <img src="../assets/drop.svg" alt="">
              <span>网络电话</span>
            </a>
            <!-- <a-menu slot="overlay">
              <a-menu-item v-for="(item,i) in calltypelist" :key="i">{{item}}</a-menu-item>
            </a-menu>
          </a-dropdown> -->
<!-- 
          <a-dropdown>
            <div class="agent-status" style="width: 70px; margin-top: 1px;" >
              SIM卡 {{currentsim}}<a-icon type="down" />
            </div>
            <a-menu slot="overlay"  @click="switchsim">
              <a-menu-item v-for="(item,i) in simlist" :key="i+1" :disabled="item">
                SIM卡{{i+1}}
              </a-menu-item>
            </a-menu>
          </a-dropdown> -->

          <div class="communicate-close"  @click="closecall">
            <img src="../assets/close.svg" alt="">
          </div>

        </div>
      </div>
      <div class="communicate-body-oper" >
        <div class="communicate-oper" id="success">
          <div class="number-input">
            <img src="../assets/call.svg" alt="">
            <a-input autocomplete="off" v-model:value="phonenum" placeholder="请输入电话号码" style="font-size: 18px;"></a-input>
          </div>

          <div class="communicate-key">
            <div class="keyboard-box" >
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='1'>1</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='2'>2</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='3'>3</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='4'>4</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='5'>5</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='6'>6</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='7'>7</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='8'>8</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='9'>9</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='*'>*</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='0'>0</span>
              <span class="keyboard-btn" @click='_handleKeyPress' data-num='#'>#</span>
            </div>

            <div class="communicate-call-btn">
              <img style="cursor:point" src="../assets/callbtn.svg"  @click="totel" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- 回呼验证 -->
    <a-modal v-model:open="telOpen" title="呼叫登录验证" @ok="toCode">
          <a-form
            name="basic"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
        >
            <a-form-item label="手机号码" name="telPhone" >
                <a-input placeholder="手机号码" v-model:value="telPhone" />
            </a-form-item>
            <a-form-item label="验证码" name="phone" class="telVerfitywrap">
                <a-input-group compact>
                    <a-input v-model:value="code" placeholder="请输入验证码" />
                    <a-button @click="getVerfity" :disabled="isdisable" style="height:30px">{{buttonText}}</a-button>
                </a-input-group>
            </a-form-item>
          </a-form>
    </a-modal>

    <iframe ref="myIframe" :src="iframeUrl" width="100" height="100" style="position: absolute;z-index:-10"></iframe>

    <video style="position: absolute;z-index:-10" ref="remoteVideo" autoplay></video>
    <video style="position: absolute;z-index:-10" ref="localVideo" autoplay="autoplay" muted="muted"></video>

    <!-- <iframe ref="myIframe2" :src="iframeUrl2" width="100" height="100" style="position: absolute;z-index:-10"></iframe> -->
  </a-layout>
</template>

<script>
import { ref,reactive } from "@vue/reactivity"
import {useRouter} from "vue-router";
import { getCurrentInstance,watch } from 'vue'
import { message} from 'ant-design-vue';
// import SIP from 'sip.js';
  export default({
    setup(){
      const router=useRouter()
      const { proxy } = getCurrentInstance()
      let collapsed=ref(false)


      // watch(() => router.currentRoute.value.query.state, (newParams, oldParams) => {
      //         getTabledata()
      //     })
      let menulist=reactive({arr:[
      ]})

      // 键盘拨打
      let callphone=ref()
      let callshow=ref(false)
      let phonenum=ref("")
      let call=function(){
        callshow.value=true
      }
      let closecall=function(){
        callshow.value=false
      }
        //处理按键
      let _handleKeyPress=function(e) {
          let num = e.target.dataset.num;
          phonenum.value=phonenum.value+num

        }
       // 获取菜单
      let getMenu=function(){
      let roleid= sessionStorage.getItem("roleid")
        if(roleid==0){
          roleid=""
        }
        proxy.$http.post('/admin/nodelist',{menu_status:1},{
            headers:{
              'token':sessionStorage.getItem("token")
            }
          }).then((res) => {
              if(res.data.status==200){
                menulist.arr=res.data.data
                // console.log(menulist.arr)
                proxy.$http.post('/admin/nodelist',{},{
                  headers:{
                    'token':sessionStorage.getItem("token")
                  }
                }).then((res) => {
                    if(res.data.status==200){
                      sessionStorage.setItem("menulist",JSON.stringify(res.data.data))
                      getFirstbtn()
                    }else{
                      message.error(res.data.msg);
                    }
                });
              }else{
                message.error(res.data.msg);
              }
          });
 
      }
      getMenu()

      let getFirstbtn=function(){
            var list=sessionStorage.getItem('menulist')
            list=eval('(' + list + ')')
            for(var i in list){
                if(list[i].title=="首页"){
                  sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                  router.push({path:"/index"})
                }
            }
        }

       // 详情
       let system=reactive({data:{log_url:""}})
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data.log_url=res.data.data.data.log_url
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()
        

      // 绑定
      let binding=function(){
          proxy.$http.post('/admin/addseatssjt',{admin_user_id:sessionStorage.getItem("id")},{
              headers:{
                  'token':sessionStorage.getItem("token")
              }
          }).then((res) => {
              if(res.data.status==200){
                  message.success(res.data.msg);
              }else{
                  message.error(res.data.msg);
              }
          });
      }

      // 账号信息
      let accountInfo=reactive({data:{}})
      let admin_seats_username=ref()
      let admin_seats_password=ref()
      let phone_type=ref()
      let getInfo=function(){
        proxy.$http.post('/admin/adminuserpage',{id:sessionStorage.getItem("id")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                  accountInfo.data=res.data.data.data
                  sessionStorage.setItem("admintype",res.data.data.data.admin_type_id)

                  console.log(99999999999999999000000000000000)


                   admin_seats_username.value=res.data.data.data.admin_seats_username
                  admin_seats_password.value=res.data.data.data.admin_seats_password
                  phone_type.value=res.data.data.data.phone_call_api_type

                  extCode1.value=res.data.data.data.sip_extension
                  extPass1.value=res.data.data.data.sip_password
                  siplocal1.value=res.data.data.data.sip_localhost
                  wssport1.value=res.data.data.data.sip_port
                }else{
                    // message.error(res.data.msg);
                }
            });
      }
      getInfo()

      // 回呼登录模块
      let telOpen=ref(false)
      let telPhone=ref(sessionStorage.getItem("telphone"))
      let code=ref()
      let countdown=ref()
      let isdisable=ref(false)
      let buttonText=ref('获取验证码')
      let toCode=function(){
        proxy.$http.post('/admin/verify_admin_seats_ycwydh',{admin_user_id:sessionStorage.getItem("id"),code:code.value,telephone:telPhone.value},{
            headers:{
                'token':sessionStorage.getItem("token")
            }
        }).then((res) => {
            if(res.data.status==200){
                message.success(res.data.msg);
                telOpen.value=false
            }else{
                message.error(res.data.msg);
            }
        });
      }

       // 获取验证码
        let getVerfity=function(){
            proxy.$http.post('/admin/sendverifycode',{telephone:telPhone.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    sessionStorage.setItem("telphone",telPhone.value)
                    message.success(res.data.msg);
                    countdown.value = 60
                    isdisable.value=true
                    const timer = setInterval(() => {
                        countdown.value--
                        buttonText.value = countdown.value+'秒后获取'
                        if (countdown.value === 0) {
                            isdisable.value=false
                            clearInterval(timer)
                            buttonText.value = '获取验证码'
                        }

                    }, 1000)
                }else{
                    message.error(res.data.msg);
                }
            });
            
        }


      // 拨打电话
      let iframeUrl="https://fxpd.13524.net/tel.html"
      const myIframe = ref(null);
      let apiurl=ref()
      var wp=""

      let extCode1=ref()
      let extPass1=ref()
      let siplocal1=ref()
      let wssport1=ref()

      let totel=function(){
        proxy.$http.post('/admin/phonecall',{custom_id:accountInfo.data.id},{
              headers:{
                  'token':sessionStorage.getItem("token")
              }
          }).then((res) => {
            if(res.data.status==200){
              if(accountInfo.data.phone_call_api_type==3){
                ishangup.value=true
                toPbx(phonenum.value)
              }else if(accountInfo.data.phone_call_api_type==2){
                if (myIframe.value && myIframe.value.contentWindow) {
                    const iframeMethod = myIframe.value.contentWindow.ant_sys_login;
                
                    if (typeof iframeMethod === 'function') {
                        let logininfo=iframeMethod("wxkj",admin_seats_username.value,admin_seats_password.value,"vs01.wei-fu.cn"); // 调用内嵌页面中的方法
                        console.log(logininfo)
                        let token=logininfo.data.token

                        const iframeWindow = myIframe.value.contentWindow;
                        console.log( myIframe.value.contentWindow)
                        const MyObject = iframeWindow.MyObject;
                    
                        wp=new MyObject(token,"vs01.wei-fu.cn","7443",function(data){
                            console.log(data);
                            if(data.value=="accepted"||data.value=="trackAdded"){
                                ishangup.value=true
                            }else{
                                ishangup.value=false
                            }
                        });
                        console.log(wp);
                        wp.ant_register()
                        wp.ant_endcall();
                        wp.ant_startcall(phonenum.value)
                    }
                }
              }
            }else if(res.data.data.api){
                  telOpen.value=true
                  apiurl.value=res.data.data.api
              }else{
                  message.error(res.data.msg);
              }
            });
      }

      // pbx拨打
        var sessionall;
        var remoteVideo = ref(null);
		    var localVideo = ref(null);
        let toPbx=function(phone){
            var extCode = extCode1.value; //SIP分机号
            var extPass = extPass1.value; //SIP分机密码
            var siplocal = siplocal1.value;//SIP服务器地址 
            var wssport = wssport1.value;//wss端口号
            
            var config = {
            uri: "sip:" + extCode + '@'+siplocal,
            authorizationUser: extCode,
            password: extPass,
            displayName: extCode,
            log: {
                builtinEnabled: true,
                level: 3 // log日志级别
            },
            transportOptions: {
                wsServers: ['wss://'+siplocal+':'+wssport], //wss协议
                traceSip: true  //开启sip日志,用于排查问题
            },
            allowLegacyNotifications: true,
            hackWssInTransport: true, // 设置为true 则注册时 transport=wss; false:transport=ws;

            //hackIpInContact: "39.100.87.201",
            hackIpInContact: true,
            userAgentString: "smarkdeskclient",
            registerOptions: {
                expires: 300,
                registrar: 'sip:registrar.mydomain.com',
            },

            contactName: "1003",
            };

            var ua = new SIP.UA(config);
            // 接受入站（用户代理服务器）会话
            ua.on('invite', function (session) {
                    

            var url = session.remoteIdentity.uri.toString() + "来电了，是否接听";

          

            session.on("terminated", function (message, cause) {
                console.error(message);

            })

            /**
             *
             */
            session.on('accepted', function (response, cause) {

                // If there is a video track, it will attach the video and audio to the same element
                var pc = this.sessionDescriptionHandler.peerConnection;
                console.error(this.sessionDescriptionHandler);
                var remoteStream;

                if (pc.getReceivers) {
                remoteStream = new window.MediaStream();
                pc.getReceivers().forEach(function (receiver) {
                    var track = receiver.track;
                    if (track) {
                    remoteStream.addTrack(track);
                    }
                });
                } else {
                remoteStream = pc.getRemoteStreams()[0];
                }
                remoteVideo.value.srcObject = remoteStream;


                var localStream_1;
                if (pc.getSenders) {
                localStream_1 = new window.MediaStream();
                pc.getSenders().forEach(function (sender) {
                    var track = sender.track;
                    if (track && track.kind === "video") {
                    localStream_1.addTrack(track);
                    }
                });
                }
                else {
                localStream_1 = pc.getLocalStreams()[0];
                }
                localVideo.value.srcObject = localStream_1;
                localVideo.value.volume = 0;


            })

            session.on('bye', function (resp, cause) {
            });


            session.on("terminated", function (message, cause) {
                console.log('terminate'+cause);
            });

            var isaccept = confirm(url);
            if (isaccept) {
                //接受来电
                session.accept({
                sessionDescriptionHandlerOptions: {
                    constraints: {
                    audio: true,
                    video: false
                    }
                }
                });
                sessionall = session;
            }
            else {
                //拒绝来电
                session.reject();
            }

            });

            //注册失败
            ua.on('registrationFailed', function (response, cause) {
            alert('注册失败'+cause)
            });


            //创建一个新的出站（用户代理客户端）会话
			sessionall = ua.invite(phone, {
				sessionDescriptionHandlerOptions: {
					constraints: {
						audio: true,
						video: false
					},
					alwaysAcquireMediaFirst: true // 此参数是sip.js官方修复在firefox遇到的bug所设置
				}
			});

      

            //处理接受183早期媒体
			sessionall.on('trackAdded', function () {

				var pc = this.sessionDescriptionHandler.peerConnection;
				var remoteStream;

				if (pc.getReceivers) {
					remoteStream = new window.MediaStream();
					pc.getReceivers().forEach(function (receiver) {
						var track = receiver.track;
						if (track) {
							remoteStream.addTrack(track);
						}
					});
				} else {
					remoteStream = pc.getRemoteStreams()[0];
				}
				remoteVideo.value.srcObject = remoteStream;

				var localStream_1;
				if (pc.getSenders) {
					localStream_1 = new window.MediaStream();
					pc.getSenders().forEach(function (sender) {
						var track = sender.track;
						if (track && track.kind === "video") {
							localStream_1.addTrack(track);
						}
					});
				}
				else {
					localStream_1 = pc.getLocalStreams()[0];
				}
				localVideo.value.srcObject = localStream_1;
			});

			//每次收到成功的最终（200-299）响应时都会触发。
			sessionall.on("accepted", function (response, cause) {
				console.log(response);
				var pc = this.sessionDescriptionHandler.peerConnection;
				var remoteStream;

				if (pc.getReceivers) {
					remoteStream = new window.MediaStream();
					pc.getReceivers().forEach(function (receiver) {
						var track = receiver.track;
						if (track) {
							remoteStream.addTrack(track);
						}
					});
				} else {
					remoteStream = pc.getRemoteStreams()[0];
				}
				remoteVideo.value.srcObject = remoteStream;


				var localStream_1;
				if (pc.getSenders) {
					localStream_1 = new window.MediaStream();
					pc.getSenders().forEach(function (sender) {
						var track = sender.track;
						if (track && track.kind === "video") {
							localStream_1.addTrack(track);
						}
					});
				}
				else {
					localStream_1 = pc.getLocalStreams()[0];
				}
				localVideo.value.srcObject = localStream_1;
			})

			//挂机时会触发
			sessionall.on("bye", function (response, cause) {
				console.log(response);
			})

			//请求失败时触发，无论是由于最终响应失败，还是由于超时，传输或其他错误。
			sessionall.on("failed", function (response, cause) {
				console.log(response);
			})

			/**
			 *
			 */
			sessionall.on("terminated", function (message, cause) {
			})

			/**
			 * 对方拒绝
			 */
			sessionall.on('rejected', function (response, cause) {
			})

			sessionall.on('progress', function (response) {
				// InviteClientContext#receiveInviteResponse
				if (response.statusCode === 183 && response.body && this.hasOffer && !this.dialog) {
					if (!response.hasHeader('require') || response.getHeader('require').indexOf('100rel') === -1) {
						if (this.sessionDescriptionHandler.hasDescription(response.getHeader('Content-Type'))) {
							// Mimic rel-100 behavior

							if (!this.createDialog(response, 'UAC')) { // confirm the dialog, eventhough it's a provisional answer
								return
							}
							this.hasAnswer = true
							this.dialog.pracked.push(response.getHeader('rseq'))
							this.status = SIP.Session.C.STATUS_EARLY_MEDIA
							this.sessionDescriptionHandler
								.setDescription(response.body, this.sessionDescriptionHandlerOptions, this.modifiers)
								.catch((reason) => {
									this.logger.warn(reason)
									this.failed(response, C.causes.BAD_MEDIA_DESCRIPTION)
									this.terminate({ status_code: 488, reason_phrase: 'Bad Media Description' })
								})




						}
					}
				}
			});
        }

      // 退出登录
      let toLoginout=function(){
        sessionStorage.setItem("token","")
        router.push({path:"/login"})
      }

      let toPerson=function(){
        router.push({path:"/person"})
      }
      

      let toRouter=function(title,href,subtitle){
        var list=sessionStorage.getItem('menulist')
        list=eval('(' + list + ')')
 
        for(var i in list){
      
            if(list[i].title==title){
                for(var j in list[i].children){
                  
                    if(list[i].children[j].title=="全部派单"||list[i].children[j].title=="待跟进派单"||list[i].children[j].title=="有效派单"||list[i].children[j].title=="未接通"||list[i].children[j].title=="重单派单"||list[i].children[j].title=="无效派单"){
                      sessionStorage.setItem("btnlist",JSON.stringify(list[i].children[0].children))
                      
                    }else if(list[i].children[j].title=="线索列表"||list[i].children[j].title=="待回访"||list[i].children[j].title=="无效"){
                      // console.log(JSON.stringify(list[i].children[0].children))
                      sessionStorage.setItem("btnlist",JSON.stringify(list[i].children[0].children))
                    }else if(list[i].title=="项目管理"){
                    sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                  } else if(list[i].title=="客户列表"){
                    sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                  }else if(list[i].title=="数据分析"){
                    sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                  }else if(list[i].title=="首页"){
                    sessionStorage.setItem("btnlist",JSON.stringify(list[i].children))
                  }else if(list[i].children[j].title==subtitle){
                    
                      console.log(JSON.stringify(list[i].children[j]))
                      sessionStorage.setItem("btnlist",JSON.stringify(list[i].children[j].children))
                      // return false
                  }
                }
            }
        }
        if(href.indexOf("?") >= 0 ) { 
          var arr=href.split("?")
          router.push({path:arr[0],query:{state:arr[1]}})
        } else{
          router.push({path:href})
        }
        // router.push({path:href})
      }
      return{
        remoteVideo,localVideo,
        totel,telOpen,toCode,iframeUrl,myIframe,telPhone,code,isdisable,getVerfity,countdown,buttonText,
        callphone,callshow,call,closecall,_handleKeyPress,phonenum,
        system,
        toLoginout,accountInfo,binding,
        menulist,toRouter,
        toPerson,
        collapsed
      }
    }
  })
</script>
<style>
.trigger {
  font-size: 20px;
  line-height: 64px;
  /* padding: 0 24px; */
  cursor: pointer;
  transition: color 0.3s;
  color: #999;
}

.trigger:hover {
  color: #1890ff;
}
li.ant-dropdown-menu-item-only-child{line-height: 46px;height: 46px;}
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}
.ant-layout-sider-light{max-width: 216px;width:216px ;flex: 0 0 216px ;}
.site-layout .site-layout-background {
  background: #fff;
}
.ant-menu{height: 100%;
    overflow-y: auto;}
.ant-menu-item span{color: #222;}
.ant-menu-item span.anticon{font-size:16px !important;}
</style>