
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加线索标签</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
             <div class="left-option">

                <a-input style="width:240px" placeholder="线索标签名称" v-model:value="search" />
                <a-button  type="primary">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'phone_call_api_type'">
                            <span v-if="record.phone_call_api_type==2">
                                蚁巢网页电话
                            </span>
                            <span v-if="record.phone_call_api_type==1">
                                商机通回呼
                                <span v-if="record.is_bind_sjt==0">（未绑定）</span>
                                <span v-if="record.is_bind_sjt==1">（已绑定）</span>
                            </span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑线索标签 -->
        <a-modal v-model:open="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="线索标签名称" name="title" >
                    <a-input placeholder="线索标签名称" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item label="所属线索状态" name="clue_state_id" >
                    <a-select ref="select"  v-model:value="formData.data.clue_state_id"   >
                        <a-select-option v-for="item in padmin.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="排序" name="title" >
                    <a-input placeholder="排序，数字越小越靠前" v-model:value="formData.data.sort" />
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '线索标签名称',dataIndex: 'title'},
    {title: '所属线索状态',dataIndex: 'title_clue_state'},
    {title: '排序',dataIndex: 'sort'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let padmin=reactive({arr:[]})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        // 线索状态
        let getPadmin=function(){
            proxy.$http.post('/admin/cluestatelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    padmin.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getPadmin()

        // 项目列表
        let institutionList=reactive({arr:[]})
        // let getInstitution=function(){
        //      proxy.$http.post('/admin/organlist',{pagesize:100},{
        //         headers:{
        //             'token':sessionStorage.getItem("token")
        //         }
        //     }).then((res) => {
        //         if(res.data.status==200){
        //             institutionList.arr=res.data.data.data
        //         }else{
        //             message.error(res.data.msg);
        //         }
        //     });
        // }
        // getInstitution()


        // 绑定
        let binding=function(){
            proxy.$http.post('/admin/addseatssjt',{admin_user_id:formData.data.id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 解除绑定
        let toSecure=function(){
            proxy.$http.post('/admin/delseatssjt',{admin_user_id:formData.data.id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // 呼叫类型
        // let getType=function(){
        //      proxy.$http.post('/admin/rolelist',{pagesize:100},{
        //         headers:{
        //             'token':sessionStorage.getItem("token")
        //         }
        //     }).then((res) => {
        //         if(res.data.status==200){
        //             roleList.arr=res.data.data.data
        //         }else{
        //             message.error(res.data.msg);
        //         }
        //     });
        // }
        // getType()

         // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/cluetaglist',{page:page.page,pagesize:page.pageSize,title:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{title:"",clue_project_abbr:"",admin_user_id:"",sort:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.sort=""
            formData.data.clue_project_abbr=""
            formData.data.admin_user_id=""
            title.value="添加线索标签"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/cluetagpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑线索标签"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致！");
            }else{
                if(title.value=="添加线索标签"){
                    proxy.$http.post('/admin/addcluetag',{title:formData.data.title,clue_state_id:formData.data.clue_state_id,sort:formData.data.sort},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }else if(title.value=="编辑线索标签"){
                    proxy.$http.post('/admin/updatecluetag',{id:formData.data.id,title:formData.data.title,clue_state_id:formData.data.clue_state_id,sort:formData.data.sort},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delcluetag',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            toSecure,binding,institutionList,
            toSearch,search,
            visible,formData,openAdd,title,openEdit,handleOk,padmin,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>