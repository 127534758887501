
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加机构</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
         <a-divider style="margin:16px 0" />
        <div class="search">
            <div class="left-option">

                <a-input style="width:240px" placeholder="机构名称" v-model:value="search" />
                <a-button  type="primary" @click="toSearch">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table size="small" :pagination="false" :columns="columns" :data-source="dataList.arr" row-key="id"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                    <template #bodyCell="{ column,record}">
                
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑机构 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
            
             <a-form
                :model="formData.data"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="机构名称" name="title" >
                    <a-input placeholder="机构名称" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item label="排序" name="sort" >
                    <a-input placeholder="排序，数字越小越靠前" v-model:value="formData.data.sort" />
                </a-form-item>

            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message ,Modal} from 'ant-design-vue';
const columns = [
  {title: 'ID',dataIndex: 'id'},
  {title: '机构名称',dataIndex: 'title'},
  {title: '添加时间',dataIndex: 'create_time'},
  {title: '排序',dataIndex: 'sort'},
  {title: '操作',dataIndex: 'operation',width:280},
];

export default {
    setup(){
        let dataList=reactive({arr:[]})
 
        const { proxy } = getCurrentInstance()
        let page=reactive({pageSize:"10",total:"20"})//分页

          // 搜索
        let search=ref()
        let toSearch=function(){
            getTabledata()
        }

        // 列表
        let getTabledata=function(){
            proxy.$http.post('/admin/organlist',{page:page.page,pagesize:page.pageSize,title:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()
        
         // 添加、编辑
        let formData=reactive({data:{title:"",sort:""}})
        let visible=ref(false)
        let title=ref("添加机构")
        let openAdd=function(value){
            formData.data.title=""
            formData.data.sort=""
            visible.value=true
            title.value="添加机构"
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/organpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑机构"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let handleOk=function(){
            if(title.value=="添加机构"){
                proxy.$http.post('/admin/addorgan',{title:formData.data.title,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑机构"){
                proxy.$http.post('/admin/updateorgan',{id:formData.data.id,title:formData.data.title,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }

        let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updatenode',{id:id,pid:formData.data.pid,menu_status:checked},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }

        // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delorgan',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
            console.log(selectedRowKeys.value )
        }
      

        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return {
            reload,
            search,toSearch,
            formData,visible,title,handleOk,openAdd,openEdit,changeStatus,
            dataList,columns,
            page,pageChange,
            onSelectChange,selectedRowKeys,del,delMore
        };
    }
}
</script>