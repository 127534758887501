
<template>
    <div>
        <div class="flexwrap">
            <div class="btn-list">
                <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加账号</a-button>
                <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
            </div>
            <a-button class="reloadbtn" @click="reload"><ReloadOutlined /> 刷新</a-button>
        </div>
        <a-divider style="margin:16px 0" />
        <div class="search">
             <div class="left-option">

                <a-input style="width:240px" placeholder="账号姓名" v-model:value="search" />
                <a-select
                    ref="select" v-model:value="searchLlist" style="width: 120px;margin-left:14px"
                    placeholder="类型"
                    allowClear
                >
                    <a-select-option v-for="item in adminType.arr" :value="item.id" :key="item.id" >{{item.title}}</a-select-option>
                </a-select>
                <a-button  type="primary" @click="toSearch">查询</a-button>
            </div>
            <div class="table-wrap">

                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                    
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'start_time'">
                            <span v-if="record.start_time!='0'">{{record.start_time}}</span>
                            <span v-else>-</span>
                        </template>
                        <template v-if="column.dataIndex === 'end_time'">
                            <span v-if="record.end_time!='0'">{{record.end_time}}</span>
                            <span v-else>-</span>
                        </template>
                        <template v-if="column.dataIndex === 'phone_call_api_type'">
                            <span v-if="record.phone_call_api_type==2">
                                蚁巢网页电话
                                
                            </span>
                             <span v-if="record.phone_call_api_type==4">
                                手机直呼
                                
                            </span>
                            <span v-if="record.phone_call_api_type==3">
                                PBX通话
                                
                            </span>
                            <span v-if="record.phone_call_api_type==1">
                                商机通回呼
                                <span v-if="record.is_bind_sjt==0">（未绑定）</span>
                                <span v-if="record.is_bind_sjt==1">（已绑定）</span>
                                <a-button type="link" v-if="record.is_bind_sjt==0&&(record.admin_type_id==5||record.admin_type_id==4)"  @click="binding(record.id)">绑定坐席</a-button>
                                <a-button type="link" v-if="record.is_bind_sjt==1&&(record.admin_type_id==5||record.admin_type_id==4)"  @click="toSecure(record.id)">解除绑定</a-button>
                            </span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                            
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>

        <!-- 添加、编辑账号 -->
        <a-drawer v-model:visible="visible" :title="title" @ok="handleOk" placement="right" size="large">
            <template #extra>
                <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
                <a-button type="primary" @click="handleOk">提交</a-button>
            </template>
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 19 }"
                autocomplete="off"
            >
                
                <h3 class="split-title">基本信息</h3>
                <a-form-item label="账号类型" name="admin_type_id" >
                    <a-radio-group @change="changAdmintype" v-model:value="formData.data.admin_type_id" name="radioGroup">
                        <a-radio v-for="item in adminType.arr" :value="item.id" :key="item.id">{{item.title}}</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item v-if="padmin.arr.length>0" label="上级管理员" name="pid" >
                    <a-select ref="select" v-model:value="formData.data.pid"   >
                        <a-select-option v-for="item in padmin.arr" :value="item.id" :key="item.id">{{item.username}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item v-if="formData.data.admin_type_id==4" label="所属机构" name="organ_id" >
                    <a-select ref="select" v-model:value="formData.data.organ_id"   >
                        <a-select-option v-for="item in institutionList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="用户名" name="username" >
                    <a-input placeholder="用户名" v-model:value="formData.data.username" />
                </a-form-item>
                <a-form-item label="手机" name="telephone" >
                    <a-input placeholder="手机" v-model:value="formData.data.telephone" />
                </a-form-item>

                
                <a-form-item label="密码" name="password" >
                    <a-input-password placeholder="密码" v-model:value="formData.data.password" />
                </a-form-item>
                <a-form-item label="确认密码" name="repassword" >
                    <a-input-password placeholder="确认密码" v-model:value="formData.data.repassword" />
                </a-form-item>
                <a-form-item label="角色权限" name="admin_role_id" >
                    <a-select ref="select" v-model:value="formData.data.admin_role_id"   >
                        <a-select-option v-for="item in roleList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item label="开始时间" name="telephone" >
                    <a-date-picker  placeholder="开始时间" valueFormat="YYYY-MM-DD" v-model:value="formData.data.start_time" />
                </a-form-item>
                <a-form-item label="结束时间" name="telephone" >
                    <a-date-picker  placeholder="结束时间" valueFormat="YYYY-MM-DD" v-model:value="formData.data.end_time" />
                </a-form-item>

                <a-divider />
                <h3 class="split-title">外呼设置</h3>
                <a-form-item  label="呼叫类型" name="phone_call_api_type" >
                    <a-select ref="select" v-model:value="formData.data.phone_call_api_type"   >
                        <a-select-option :value="3">PBX通话</a-select-option>
                        <a-select-option :value="4">手机直呼</a-select-option>
                        <a-select-option :value="2">蚁巢网页电话</a-select-option>
                        <a-select-option :value="1">商机通回呼</a-select-option>
                        
                    </a-select>
                </a-form-item>
                <div v-if="formData.data.phone_call_api_type==3">
                    <a-form-item label="SIP分机号" name="sip_extension" >
                        <a-input placeholder="SIP分机号" v-model:value="formData.data.sip_extension" />
                    </a-form-item>
                    <a-form-item label="SIP分机密码" name="sip_password" >
                        <a-input placeholder="SIP分机密码" v-model:value="formData.data.sip_password" />
                    </a-form-item>
                    <a-form-item label="SIP服务器地址" name="sip_localhost" >
                        <a-input placeholder="SIP服务器地址" v-model:value="formData.data.sip_localhost" />
                    </a-form-item>
                    <a-form-item label="端口号" name="sip_port" >
                        <a-input placeholder="端口号" v-model:value="formData.data.sip_port" />
                    </a-form-item>
                    
                </div>
                <a-form-item v-if="formData.data.phone_call_api_type==1" label="绑定坐席" name="admin_role_id" >
                    <a-button v-if="formData.data.phone_call_api_type==1" type="primary" @click="binding">绑定坐席</a-button>
                    <a-button v-if="formData.data.is_bind_sjt==1&&formData.data.phone_call_api_type==1" type="primary" @click="toSecure">解除绑定</a-button>
                </a-form-item>
            </a-form>
            

            <a-divider />
            <h3 class="split-title">费用设置</h3>
            <a-form
                :model="formData.data"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
                autocomplete="off"
               
            >
                <div  class="form-inline">
                    
                    <a-form-item v-for="item in filedList.arr" :label="item.title" :name="item.field" :key="item.id">
                        <div class="three-div" v-if="item.title=='派单（可用-条）'">
                            <a-input :placeholder="item.title" disabled v-model:value="formData.data[item.field]" />
                            <a-input style="margin-left:12px" placeholder="增加条数" v-model:value="formData.data.admin_user_custom_no_inc" />
                            <a-input style="margin-left:12px" placeholder="减少条数" v-model:value="formData.data.admin_user_custom_no_dec" />
                        </div>
                        <div class="three-div" v-else-if="item.title=='线索（可用-条）'">
                            <a-input :placeholder="item.title" disabled v-model:value="formData.data[item.field]" />
                            <a-input style="margin-left:12px" placeholder="增加条数" v-model:value="formData.data.admin_user_clue_no_inc" />
                            <a-input style="margin-left:12px" placeholder="减少条数" v-model:value="formData.data.admin_user_clue_no_dec" />
                            
                        </div>
                        <div class="three-div"  v-else-if="item.title=='坐席通信余额'">
                            <a-input :placeholder="item.title" disabled v-model:value="formData.data[item.field]" />
                            <a-input style="margin-left:12px" placeholder="增加余额" v-model:value="formData.data.seats_cost_balance_inc" />
                            <a-input style="margin-left:12px" placeholder="减少余额" v-model:value="formData.data.seats_cost_balance_dec" />
                        </div>
                        <a-input v-else :placeholder="item.title" v-model:value="formData.data[item.field]" />
                    </a-form-item>
                </div>
                
            </a-form>
        </a-drawer>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import moment from "moment";
import "dayjs/locale/zh-cn";

const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    
    {title: '账号',dataIndex: 'username'},
    {title: '手机号',dataIndex: 'telephone'},
    {title: '类型',dataIndex: 'admin_type_title'},
    {title: '呼叫类型',dataIndex: 'phone_call_api_type'},
    {title: '所属管理员',dataIndex: 'pusername'},
    {title: '角色',dataIndex: 'title_admin_role'},
    {title: '开始日期',dataIndex: 'start_time'},
    {title: '截止日期',dataIndex: 'end_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        console.log("proxy=")
        console.log(proxy)
        let dataList=reactive({arr:[]})
        let roleList=reactive({arr:[]})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        // 角色
         let getRole=function(){
             proxy.$http.post('/admin/rolelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    roleList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getRole()

        let onClose=function(){
            visible.value = false;
        }

        // 字段
        let filedList=reactive({arr:[]})
        let getFiled=function(){
             proxy.$http.post('/admin/adminuserfieldlist',{pagesize:500},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    filedList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getFiled()

        // 绑定
        let binding=function(id){
            proxy.$http.post('/admin/addseatssjt',{admin_user_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 解除绑定
        let toSecure=function(id){
            proxy.$http.post('/admin/delseatssjt',{admin_user_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    getTabledata()
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 账号类型
        let adminType=reactive({arr:[]})
        let getAdminType=function(){
            proxy.$http.post('/admin/admintypelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    adminType.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getAdminType()

         // 机构列表
        let institutionList=reactive({arr:[]})
        let getInstitution=function(){
             proxy.$http.post('/admin/organlist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    institutionList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getInstitution()

        // 上级管理员
        let padmin=reactive({arr:[]})
        let changAdmintype=function(e){
            if(e.target.value==2){
                padmin.arr=[]
            }else if(e.target.value==5){
                getPadmin(4)
            }else if(e.target.value==4||e.target.value==3){
                getPadmin(2)
            }
            
        }
        let getPadmin=function(type){
            proxy.$http.post('/admin/adminuserlist',{pagesize:100,admin_type_id:type},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    padmin.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // 呼叫类型
        // let getType=function(){
        //      proxy.$http.post('/admin/rolelist',{pagesize:100},{
        //         headers:{
        //             'token':sessionStorage.getItem("token")
        //         }
        //     }).then((res) => {
        //         if(res.data.status==200){
        //             roleList.arr=res.data.data.data
        //         }else{
        //             message.error(res.data.msg);
        //         }
        //     });
        // }
        // getType()

         // 搜索
        let search=ref()
        let searchLlist=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/adminuserlist',{page:page.page,pagesize:page.pageSize,username:search.value,admin_type_id:searchLlist.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{pid:"",username:"",telephone:"",password:"",repassword:"",admin_role_id:"",admin_type_id:"",
        start_time:"",end_time:"",sip_extension:"",sip_localhost:"",sip_port:"",sip_password:"",
        seats_cost_monthly:"",call_no_daily:"",call_no_monthly:"",call_unit_price_per_minute:"",call_min_consumption_monthly:"",
        admin_user_points:"",data_unit_price_each:"",seats_cost_balance:"",admin_user_points_all:"",admin_user_points_used:"",seats_cost_balance_all:"",seats_cost_balance_used:"",
        data_unit_price_each_custom:"",admin_user_clue_no:"",admin_user_clue_no_all:"",admin_user_clue_no_used:"",admin_user_custom_no:"",admin_user_custom_no_all:"",admin_user_custom_no_used:"",
        is_bind_sjt:0,phone_call_api_type:"",organ_id:"",
        admin_user_clue_no_dec:"",admin_user_clue_no_inc:"", admin_user_custom_no_inc:"",admin_user_custom_no_dec:"",seats_cost_balance_inc:"",seats_cost_balance_dec:"",
        }})
       
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.start_time=moment().format("YYYY-MM-DD");
            formData.data.pid=""
            padmin.arr=[]
            formData.data.username=""
            formData.data.telephone=""
            formData.data.password=""
            formData.data.organ_id=""
            formData.data.repassword=""
            formData.data.admin_role_id=""
            formData.data.admin_type_id=""
            formData.data.is_bind_sjt=0
            formData.data.phone_call_api_type=""
            formData.data.end_time="",formData.data.sip_extension="",formData.data.sip_localhost="",formData.data.sip_port="",formData.data.sip_password="",
            formData.data.seats_cost_monthly="",formData.data.call_no_daily="",formData.data.call_no_monthly="",formData.data.call_unit_price_per_minute="",formData.data.call_min_consumption_monthly="",
            formData.data.admin_user_points="",formData.data.data_unit_price_each="",formData.data.seats_cost_balance="",formData.data.admin_user_points_all="",formData.data.admin_user_points_used="",
            formData.data.seats_cost_balance_all="",formData.data.seats_cost_balance_used="",
            formData.data.data_unit_price_each_custom="",formData.data.admin_user_clue_no="",formData.data.admin_user_custom_no="",
            formData.data.admin_user_clue_no_dec="",formData.data.admin_user_clue_no_inc="", formData.data.admin_user_custom_no_inc="",formData.data.admin_user_custom_no_dec="",formData.data.seats_cost_balance_inc="",formData.data.seats_cost_balance_dec="",
            title.value="添加账号"
            visible.value=true
        }

        let clue_no=ref()
        let custom_no=ref()
        let openEdit=function(id){
            
            proxy.$http.post('/admin/adminuserpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑账号"
                    formData.data=res.data.data.data
                    formData.data.password=""
                    formData.data.repassword=""
                    clue_no.value=res.data.data.data.admin_user_clue_no
                    custom_no.value=res.data.data.data.admin_user_custom_no
                    if(formData.data.start_time=="0"){
                        formData.data.start_time=""
                    }
                    if(formData.data.end_time=="0"){
                        formData.data.end_time=""
                    }
                    if(res.data.data.data.organ_id){
                        formData.data.organ_id=parseInt(res.data.data.data.organ_id)
                    }
                    if(formData.data.admin_type_id==2){
                        padmin.arr=[]
                    }else if(formData.data.admin_type_id==5){
                        getPadmin(4)
                    }else if(formData.data.admin_type_id==4||formData.data.admin_type_id==3){
                        getPadmin(2)
                    }
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            console.log(formData.data.start_time)
            var start=0
            var end=0
            if(formData.data.start_time){
                start=moment(formData.data.start_time).unix()
            }
            if(formData.data.end_time){
                end=moment(formData.data.end_time).unix()
            }
            if(formData.data.admin_type_id==2){
                formData.data.pid=1
            }
            
            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致！");
            }else{
                if(title.value=="添加账号"){
                    proxy.$http.post('/admin/addadminuser',{pid:formData.data.pid,username:formData.data.username,telephone:formData.data.telephone,
                    password:formData.data.password,admin_role_id:formData.data.admin_role_id,organ_id:formData.data.organ_id,
                    admin_type_id:formData.data.admin_type_id,phone_call_api_type:formData.data.phone_call_api_type,
                    start_time:start,end_time:end,sip_extension:formData.data.sip_extension,sip_localhost:formData.data.sip_localhost,sip_port:formData.data.sip_port,sip_password:formData.data.sip_password,
                    seats_cost_monthly:formData.data.seats_cost_monthly,call_no_daily:formData.data.call_no_daily,call_no_monthly:formData.data.call_no_monthly,call_unit_price_per_minute:formData.data.call_unit_price_per_minute,call_min_consumption_monthly:formData.data.call_min_consumption_monthly,
                    admin_user_points:formData.data.admin_user_points,data_unit_price_each:formData.data.data_unit_price_each,seats_cost_balance:formData.data.seats_cost_balance,admin_user_points_all:formData.data.admin_user_points_all,admin_user_points_used:formData.data.admin_user_points_used,
                    seats_cost_balance_all:formData.data.seats_cost_balance_all,seats_cost_balance_used:formData.data.seats_cost_balance_used,
                    data_unit_price_each_custom:formData.data.data_unit_price_each_custom,admin_user_clue_no:formData.data.admin_user_clue_no,
                    admin_user_custom_no:formData.data.admin_user_custom_no,
                    admin_user_clue_no_dec:formData.data.admin_user_clue_no_dec,admin_user_clue_no_inc:formData.data.admin_user_clue_no_inc, 
                    admin_user_custom_no_inc:formData.data.admin_user_custom_no_inc,admin_user_custom_no_dec:formData.data.admin_user_custom_no_dec,
                    seats_cost_balance_inc:formData.data.seats_cost_balance_inc,seats_cost_balance_dec:formData.data.seats_cost_balance_dec,

                    
                    },{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }else if(title.value=="编辑账号"){
                    // if(clue_no.value== formData.data.admin_user_clue_no){
                    //     formData.data.admin_user_clue_no=""
                        
                    // }
                    // if(custom_no.value==formData.data.admin_user_custom_no){
                    //     formData.data.admin_user_custom_no=""
                    // }
                   
                    proxy.$http.post('/admin/updateadminuser',{id:formData.data.id,pid:formData.data.pid,username:formData.data.username,telephone:formData.data.telephone,
                    password:formData.data.password,admin_role_id:formData.data.admin_role_id,organ_id:formData.data.organ_id,
                    admin_type_id:formData.data.admin_type_id,phone_call_api_type:formData.data.phone_call_api_type,
                    start_time:start,end_time:end,sip_extension:formData.data.sip_extension,sip_localhost:formData.data.sip_localhost,sip_port:formData.data.sip_port,sip_password:formData.data.sip_password,
                    seats_cost_monthly:formData.data.seats_cost_monthly,call_no_daily:formData.data.call_no_daily,call_no_monthly:formData.data.call_no_monthly,call_unit_price_per_minute:formData.data.call_unit_price_per_minute,call_min_consumption_monthly:formData.data.call_min_consumption_monthly,
                    admin_user_points:formData.data.admin_user_points,data_unit_price_each:formData.data.data_unit_price_each,seats_cost_balance:formData.data.seats_cost_balance,admin_user_points_all:formData.data.admin_user_points_all,admin_user_points_used:formData.data.admin_user_points_used,
                    seats_cost_balance_all:formData.data.seats_cost_balance_all,seats_cost_balance_used:formData.data.seats_cost_balance_used,
                    data_unit_price_each_custom:formData.data.data_unit_price_each_custom,admin_user_clue_no:formData.data.admin_user_clue_no,
                    admin_user_custom_no:formData.data.admin_user_custom_no,
                    admin_user_clue_no_dec:formData.data.admin_user_clue_no_dec,admin_user_clue_no_inc:formData.data.admin_user_clue_no_inc, 
                    admin_user_custom_no_inc:formData.data.admin_user_custom_no_inc,admin_user_custom_no_dec:formData.data.admin_user_custom_no_dec,
                    seats_cost_balance_inc:formData.data.seats_cost_balance_inc,seats_cost_balance_dec:formData.data.seats_cost_balance_dec,
                    
                    },{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deladminuser',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let reload=function(){
            getTabledata()
        }
        return{
            reload,
            onClose,
            filedList,getFiled,
            toSecure,binding,
            institutionList,
            toSearch,search,searchLlist,
            visible,formData,openAdd,title,openEdit,handleOk,roleList,adminType,padmin,changAdmintype,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>