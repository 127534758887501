<template>
    <div>
        <a-card title="基本信息" :bordered="false" >
            <a-form
                name="basic"
                :label-col="{ span: 2 }"
                :wrapper-col="{ span: 6 }"
                autocomplete="off"
            >
                <a-form-item label="姓名" name="username" >
                    {{userinfo.data.username}}
                </a-form-item>
                <a-form-item label="电话" name="telephone" >
                    {{userinfo.data.telephone}}
                </a-form-item>
                <a-form-item label="角色" name="username" >
                    {{userinfo.data.title_admin_role}}
                </a-form-item>

                <a-form-item label="密码" name="password" >
                    <div style="display: flex;" v-if="ispwd==false">
                        <a-input-password style="margin-right:20px" v-model:value="userinfo.data.password" placeholder="请输入新密码" />
                        <a-button @click="ClosePwd" style="margin-right:20px" type="primary" danger ><CloseOutlined /></a-button>
                        <a-button @click="confrimPwd" type="primary" ><CheckOutlined /></a-button>
                    </div>
                    <a-button v-if="ispwd" @click="openPwd" type="primary" ><EditOutlined /> 修改密码</a-button>
                </a-form-item>

            </a-form>
        </a-card>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import { message ,Modal} from 'ant-design-vue';
import { getCurrentInstance } from 'vue'
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let userinfo=reactive({data:{}})

        var id=ref()
        let getUserifo=function(){
            proxy.$http.post('/admin/adminuserpage',{id:sessionStorage.getItem("id")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userinfo.data=res.data.data.data
                    id.value=res.data.data.data.id
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserifo()

        // 编辑
        let ispwd=ref(true)
        let openPwd=function(){
            userinfo.data.password=""
            ispwd.value=false
        }
        let ClosePwd=function(){
            ispwd.value=true
        }
        let password=ref()
        let confrimPwd=function(){
            proxy.$http.post('/admin/updateadminuser',{id:id.value,password:userinfo.data.password},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    ispwd.value=true
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        return{
            userinfo,password,
            ispwd,openPwd,ClosePwd,confrimPwd
        }
    }
}
</script>