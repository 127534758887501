<template>
    <div>
        <!-- <div class="btn-list">
            <a-button type="primary" @click="openAdd"><template #icon><PlusOutlined /></template>添加权限</a-button>
            <a-button type="primary" danger @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
        </div> -->
         <div class="search">
            <!-- <div class="left-option">
                <a-range-picker :presets="rangePresets" v-model:value="searchLlist.date" />

                <a-select
                    ref="select" v-model:value="searchLlist.seat" style="width: 120px;margin-left:14px"
                    placeholder="客服"
                    allowClear
                >
                    <a-select-option value="jack">Jack</a-select-option>
                    <a-select-option value="lucy">Lucy</a-select-option>
                    <a-select-option value="Yiminghe">yiminghe</a-select-option>
                </a-select>

                <a-select ref="select" v-model:value="searchLlist.jg" style="width: 120px;margin-left:14px"
                    placeholder="机构"
                    allowClear
                >
                    <a-select-option value="jack">Jack</a-select-option>
                    <a-select-option value="lucy">Lucy</a-select-option>
                    <a-select-option value="Yiminghe">yiminghe</a-select-option>
                </a-select>
                <a-button  type="primary">查询</a-button>
            </div> -->

            <div class="table-wrap">
                <a-table size="small" row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowkeys: selectedRowkeys, onChange: onSelectChange }">
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'create_time'">
                            <span v-if="record.create_time!=0">{{ changeFormdate(record.create_time) }}</span>
                            <span v-else></span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">详情</a-button>
                        </template>
                    </template>
                </a-table>
                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>
        </div>
    </div>

</template>

<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import { formatDate } from "../../common/filter.js";
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '操作菜单',dataIndex: 'title'},
   
    {title: 'URL',dataIndex: 'url'},
    {title: '操作人',dataIndex: 'username'},
    {title: '操作IP',dataIndex: 'ip'},
    {title: '操作时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
   
export default {
    
    setup(){
         const changeFormdate = computed(() => {
            return (time) => {
                let date = new Date(time);
                return formatDate(date);
            };
        });
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let searchLlist=reactive({date:"",seat:undefined,jg:undefined})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 搜索
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }



        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/adminloglist',{page:page.page,pagesize:page.pageSize},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

       
        // 多选
        let selectedRowkeys=ref()
        let onSelectChange=function(selected) {
            selectedRowkeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowkeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delscript',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            changeFormdate,
            toSearch,searchLlist,
            onSelectChange,delMore,del,selectedRowkeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>