<template>
    <div>
        <div class="search">
            <a-tabs animated :size="size">
                <a-tab-pane key="1" tab="网站信息" class="person-wrap">
                    <a-form
                        class="system-wrap"
                        :model="system.data"
                        name="basic"
                        :label-col="{ span: 3 }"
                        :wrapper-col="{ span: 8 }"
                        autocomplete="off"
                        style="width:80%"
                    >
                        <a-form-item label="网站名称" >
                            <a-input v-model:value="system.data.title" placeholder="网站名称"/>
                        </a-form-item>

                        <a-form-item label="Logo" >
                            <a-upload
                                v-model:file-list="fileList"
                                name="file"
                                list-type="picture-card"
                                class="avatar-uploader logoUpload"
                                :headers="headers"
                                :show-upload-list="false"
                                action="https://pdxt.13524.net/admin/addlogfile"
                                @change="({ file }) => handleChangeImg(file)"
                            >
                                <img v-if="system.data.log_url" :src="'https://pdxt.13524.net/'+system.data.log_url" style="width:168px;height:32px" alt="avatar" />
                                <div v-else>
                                    <loading-outlined v-if="loading"></loading-outlined>
                                    <plus-outlined v-else></plus-outlined>
                                    <div class="ant-upload-text">图片上传</div>
                                </div>
                                
                            </a-upload>
                            <span>（图片大小为168*32）</span>
                        </a-form-item>
                        <a-form-item :wrapper-col="{ offset: 3}">
                            <a-button type="primary" @click="toSubmit">确定</a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance , reactive,ref} from 'vue'
import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let system=reactive({data:{title:"",log_url:""}})

        let fileList=ref()

         // 详情
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()



        // 上传logo
        let loading=ref(false)
        let headers=reactive({'token':sessionStorage.getItem("token")})
         let handleChangeImg=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                system.data.log_url=info.response.data.file_src
            }
        }

        // 修改配置
        let toSubmit=function(){
            proxy.$http.post('/admin/updatesystemset',{id:1,title:system.data.title
            ,log_url:system.data.log_url
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }


        return{
            system,toSubmit,
            handleChangeImg,loading,headers,fileList
        }
    }
}
</script>