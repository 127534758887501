
import { createApp } from 'vue'
import './assets/style.css'
import App from './App.vue'
import router from './router'
import common from './common/common.js'
import axios from 'axios'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import hasPermission from './common/btnControl.js';

import * as Icons from '@ant-design/icons-vue'

const app = createApp(App)
app.directive('has', hasPermission);
app.use(Viewer, {
    Options: {
        'inline': true,
        'button': true, //右上角按钮
        "navbar": true, //底部缩略图
        "title": false, //当前图片标题
        "toolbar": true, //底部工具栏
        "tooltip": true, //显示缩放百分比
        "movable": true, //是否可以移动
        "zoomable": true, //是否可以缩放
        "rotatable": true, //是否可旋转
        "scalable": true, //是否可翻转
        "transition": true, //使用 CSS3 过度
        "fullscreen": true, //播放时是否全屏
        "keyboard": true, //是否支持键盘
    }
})

import { Button, Card, Checkbox, ConfigProvider, DatePicker, Descriptions, Divider, Drawer, Dropdown, Form, Input, Layout, Menu, Modal, Pagination, Popconfirm, Popover, Radio, Select, Switch, Table, Tabs, Tag, Timeline, Tooltip, Tree, TreeSelect, Upload } from 'ant-design-vue'

app.use(Button)
app.use(Layout)
app.use(Menu)
app.use(Dropdown)
app.use(Card)
app.use(Form)
app.use(Input)
app.use(Table)
app.use(DatePicker)
app.use(ConfigProvider)
app.use(Select)
app.use(Pagination)
app.use(Divider)
app.use(Modal)
app.use(Tabs)
app.use(Radio)
app.use(Upload)
app.use(Popconfirm)
app.use(Tree)
app.use(Drawer)
app.use(Timeline)
app.use(Descriptions)
app.use(Tag)
app.use(Switch)
app.use(TreeSelect)
app.use(Tooltip)
app.use(Popover)
app.use(Checkbox)
for (let iconName in Icons){
    app.component(iconName, Icons[iconName])
}


// 配置全局限制方法
app.config.globalProperties.$noMulClicks = common.noMulClicks
// createApp(App).use(vuetyped).mount('#app') 
//基础配置
axios.defaults.baseURL = "https://pdxt.13524.net";
// axios.defaults.baseURL = "https://pdxttest.13524.net";
app.config.globalProperties.$http =axios


app.use(router);
app.mount('#app')

// createApp(App).use(router).mount('#app')
