<template>
    <div>
        <div class="search">
            <a-tabs animated :size="size">
                <a-tab-pane key="1" tab="功能设置" class="person-wrap">
                    <a-form
                        class="system-wrap"
                        name="basic"
                        autocomplete="off"
                        style="width:90%;margin:0 auto"
                        layout="vertical"
                    >
                        <a-form-item label="放弃线索后，是否保留：" >
                            <a-radio-group v-model:value="type" name="radioGroup">
                                <a-radio :value="0">不保留日志</a-radio>
                                <a-radio :value="1">保留日志</a-radio>
                            </a-radio-group>
                        </a-form-item>
                        <a-form-item >
                            <a-button type="primary" @click="toSubmit">确定</a-button>
                        </a-form-item>
                    </a-form>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance , reactive,ref} from 'vue'
import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let system=reactive({data:{title:"",log_url:""}})

        let type=ref()

         // 详情
        let getSystem=function(){
          proxy.$http.post('/admin/adminuserpage',{id:sessionStorage.getItem("id")},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                  type.value=res.data.data.data.is_reserve_clue_log
                }else{
                    // message.error(res.data.msg);
                }
            });
        }
        getSystem()


        // 修改配置
        let toSubmit=function(){
            proxy.$http.post('/admin/updateadminuser',{id:sessionStorage.getItem("id"),is_reserve_clue_log:type.value
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }


        return{
            system,toSubmit,
            type
        }
    }
}
</script>